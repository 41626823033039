/*
 * ホーム画面
 */
.home {


    .container {

        h2 {
            margin: 2em 0 1em;
        }

        .card {
            width: 100%;
            height: 120px;
            margin-bottom: 2em;

            a {
                text-decoration: none;
            }
        }
    }
}