/* Bootstrap */
.btn {
    white-space: nowrap;
}


.table-has-submenu {
    tbody {
        tr {
            td {
                .submenu {
                    display: block;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        visibility: hidden;

                        &+li {
                            border-left: solid 1px #ccc;
                            padding-left: .5em;
                        }

                        display: inline;
                        font-size: 90%;

                        i,
                        .fa,
                        .fas {
                            margin-right: .3em;
                        }
                    }

                }
            }

            &:hover {
                td {
                    .submenu {
                        li {
                            visibility: visible;
                        }
                    }
                }

            }
        }
    }
}

.fas {
    text-decoration: none;
}

.btn {

    .fa,
    .fas,
    .far {
        margin-right: .25em;
    }
}

.form-check {
    .form-check-label {
        cursor: pointer;
    }
}

.cf::after {
    content: "";
    display: block;
    clear: both;
}

// Variables
@import "variables";
$th_color: #f7f7f9;
$table_hoverd_cell: #f5f5f1;


.clearfix {
    *zoom: 1;

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

* {
    box-sizing: border-box;
}

button,
select {
    cursor: pointer;

}


.mr-5px {
    margin-right: 5px;
}

.alert {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin-bottom: 0;
    }

    &.alert-success a {
        color: #155724;
    }

    &.alert-warning a {
        color: #856404;
    }

    &.alert-danger a {
        color: #721c24;
    }
}

body {
    padding-top: 70px;

    /*
  .container-fluid {
      max-width: 1200px;
      margin: auto;
  }
*/
    &.narrow .container-fluid {
        max-width: 740px;
    }

    &.full .container-fluid {
        max-width: 100%;
    }

    &.left .container-fluid {
        margin-left: 0;
    }


    .user-photo {
        width: 32px;
        height: 32px;
        display: block;
        border-radius: 50%;
    }


    .small {
        font-size: 80%;
        font-weight: normal;
        color: #666;
    }



    .strong {
        font-weight: bold;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 1.5em;

        .small {
            font-size: 55%;
            margin-left: 1em;
        }
    }

    .page-title {
        margin-top: 0;
        font-size: 1.75rem;
        line-height: 1;

        .badge {
            font-size: 50%;
            width: 80px;
        }

        .tb {
            vertical-align: middle;
            display: inline-block;
            font-size: .5em;

            .btn {
                padding: .25rem .5rem;
                font-size: .875rem;
                line-height: 1.5;
                border-radius: .2rem;
                margin-left: 10px;

                .fa,
                .fas,
                .far,
                .svg-inline--fa {
                    margin-right: .5rem;
                }
            }
        }
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.2rem;

        small {
            font-size: 1rem;
        }
    }

    .site-footer {
        margin-top: 2em;
        padding: .5rem 1rem;
        font-size: 80%;
        color: #ccc;

        a {
            color: #666;

            &:hover {
                text-decoration: underline;
            }
        }
    }




    .mt1em {
        margin-top: 1em;
    }



    .row.lr-half {
        margin: 1em 0 0 0;

        .col-6:first-child {}

        .col-6:last-child {
            text-align: right;
        }
    }



    .navbar {
        border: none;
        background-color: #fafafa;
        color: #fff;
        background-color: #003994;
        border: none;

        .navbar-brand,
        .nav-link {
            color: #fff;

            &:hover {
                color: #eee;
            }
        }

        .dropdown-item {
            .fas {
                opacity: 0.5;
                margin: 0 .5em;
            }
        }

        .navbar-btn {
            display: inline-block;
            width: 30px;
            height: 30px;

            padding: 0 !important;
            color: #fff;
            border: none;
            border-radius: 50%;
            position: relative;
            text-align: center;
            margin-top: 5px;

            .svg-inline--fa,
            .fa,
            .fas,
            .far {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 7px;

            }

            &::after {
                display: none;
            }
        }




        .dropdown.show .navbar-btn,
        .navbar-btn:hover {
            color: #4651a4;
            background-color: rgba(255, 255, 255, .9);

        }

        .dropdown-item.disabled,
        .dropdown-item:disabled {
            cursor: default;
            color: #bbb;
        }

        .profile-menu .dropdown-menu {
            margin-top: -2px;
        }

        .dropdown {
            .label {
                color: #999;
                width: 100%;
                padding: .1rem .8rem;
                font-size: 76%;
            }



            /* navbarのドロップダウンはhoverで表示するよう変更　
      &:hover>.dropdown-menu {
        display: block;


      }

      .dropdown-menu-end {
        right: 0;
        left: auto
      }


      >.dropdown-toggle:active {
        pointer-events: none;
      }
      */
        }


        .navbar-collapse {
            height: 30px;

            .navbar-nav {
                position: absolute;
            }

            .navbar-nav-left {


                .nav-item {

                    &.dropdown,
                    &.nav-item-right {
                        margin-right: 15px;
                    }

                    &.nav-item-title {
                        margin-top: 7px;

                        a {
                            color: #fff !important;
                            text-decoration: none;
                        }
                    }
                }




            }

            .navbar-nav-right {
                right: 1rem;

                .nav-link {
                    position: relative;
                }

                .nav-link.disabled {
                    cursor: pointer;
                    color: #fff;
                    opacity: 0.5;
                }

                img {
                    display: inline-block;
                    width: 32px;
                    height: 32px;


                }




                .profile {
                    white-space: nowrap;


                    .name {
                        color: #000;
                        font-weight: bold;
                    }

                    .group {
                        font-size: 90%;
                        color: #666;
                    }

                    .attr {
                        font-size: 80%;
                        color: #999;
                    }
                }
            }
        }

    }

    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }


    .invalid-feedback {
        display: block;
    }




    .navbar-brand {
        font-size: 1rem;

        .far {
            margin-right: .25em;
        }
    }
}



.edit-form {
    width: 1200px;
    margin: auto;

}

.delete-form {
    margin-top: 20px;
}





select.groups-list {
    .company-0 {
        border-top: solid 1px #ccc;
    }

}

.btn-delete {
    border: none;
    color: #c00;
    background: none;
    font-size: 90%;
}

.nav-buttons {
    margin-bottom: 2em;

    .btn {
        margin-right: .5em;
    }
}

label {
    position: relative;

    .required {
        position: absolute;
        right: 0;
        top: 10px;

        color: #c00;
        font-size: 11px;
    }
}

/** テーブルここから **/
.table {
    margin-top: 20px;

    thead th,
    tbody th {
        text-align: center;
    }

    thead th,
    thead td,
    tbody th,
    tbody td {
        vertical-align: middle;
        overflow: hidden;
    }

    .user_name {
        width: 120px;
        white-space: nowrap;
    }

    .user_count {
        width: 80px;
    }

    td.user_count {
        text-align: right;
        white-space: nowrap;
    }

    .sort {
        width: 80px;
    }

    td.sort {
        text-align: center;
    }

    .id {
        width: 40px;
    }

    .clickable {
        cursor: pointer;

        &:hover {
            background: $table_hoverd_cell;
        }

        .nonclickable {
            cursor: default;

        }
    }

    .status {
        width: 100px;
        white-space: nowrap;
        text-align: center;
    }

    .date {
        width: 100px;
        white-space: nowrap;
        text-align: center;
    }

    .datetime {
        width: 160px;
        white-space: nowrap;
        text-align: center;
    }

    td.name {
        white-space: nowrap;
    }

    tbody {
        .price {
            text-align: right;
            width: 150px;
        }
    }

}

$col_user_retirement: #f6f6f6;
$col_user_leave: #f5f5dc;

/* ラケットテーブル */
table.rackets-table {
    width: 100%;

    .name {
        width: auto;
        overflow: hidden;
    }

}

/* 中古ラケットテーブル */
table.used_rackets-table {
    .no {
        width: 100px;
    }

    .shop {
        width: 80px;
    }

}

/**/

table.racket_makers-table {
    .name {
        width: auto;
        overflow: hidden;
    }

    .types {
        width: 320px;
        white-space: nowrap;
        text-align: center;

        .text-muted {
            opacity: .2;
        }
    }

}



.form__images {
    .row {
        margin-bottom: 1em;

        .col-1 {
            width: 72px;
            white-space: nowrap;
            vertical-align: middle;
        }

        .thumbnail {
            width: 120px;
            max-height: 90px;
            overflow: hidden;

            img {
                width: 120px;
            }
        }
    }


    /*
  display: flex;
  flex-wrap: wrap;

  &__item {

    width: 80px;
    height: 80px;
    margin-right: 10px;
    position: relative;
    border: solid 1px #ccc;
    overflow: hidden;

    input {
      width: 100%;
    }

    &-img {
      display: none;
    }

    &-del {
      position: absolute;
    }
  }
*/
}


// 一括編集
.product_bulk_edit {
    .item-table {
        margin-top: 2em;

        .row {
            margin: .25em 0;
        }
    }

    #btn-add_item {
        display: block;
        width: 500px;
        margin: 0 auto 2em;
    }

}

/** ログイン画面　　**/
.login {

    margin: 0;
    width: 100%;
    height: 100%;
    color: #fff;

    background-color: #008cd7;
    background: linear-gradient(135deg, #008cd7, #541b86)fixed;

    a,
    a:hover {
        color: #fff;
    }

    form {
        display: block;
    }

    .container-fluid {
        padding: 0;
        width: 100%;
        height: 100%;

        .alert {
            background: none;
            border-color: #fff;
            color: #fff;
        }

        .useragent {
            color: #333;
            background: rgba(200, 200, 200, .85);
            padding: 10px;
            margin-top: 20px;
            font-size: 88%;
            border-radius: 4px;
            text-align: center;

            p {
                margin-bottom: 10px;
            }

            .browser {
                width: 32px;
                height: 32px;
                margin: 0 5px;
            }
        }

        .login-box {
            width: 360px;
            height: 320px;
            margin: auto;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            .title {
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 1em;
            }

            form {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                width: 100%;

                .help-block {
                    font-size: 90%;
                    color: #ff0;
                }

                button {
                    background: #fff;
                    color: #008cd7;
                    border: none;

                    &:hover {
                        opacity: 0.75;
                    }
                }

            }

            .form-in {
                position: relative;

                .form-group {
                    margin: 0;

                    .form-control {
                        border: none;
                    }

                    #email {
                        border-radius: .25rem .25rem 0 0;
                        border-bottom: solid 1px #ccc;
                    }

                    #password {
                        border-radius: 0 0 .25rem .25rem;
                    }
                }

                .link-small {
                    display: block;
                    text-align: center;
                    font-size: 86%;
                    margin: 16px 0;
                }

                .btn-login {
                    position: absolute;
                    top: 41px;
                    right: 0;
                    border: none;
                    background: none;
                    color: #ccc;

                    &:hover {
                        color: #008cd7;
                    }
                }
            }
        }
    }

}


@media print {

    .navbar,
    .nav-buttons,
    .page-title .tb,
    .site-footer,
    .noprint,
    .devmsg {
        display: none !important;
    }

    a {
        color: inherit !important;
        text-decoration: inherit !important;
    }

    .block {
        page-break-inside: avoid;
    }

    body {
        padding: 0;
    }
}




.modal-title {
    margin-top: 0;
}