// 商品一覧
.item_list {


    .fixed-button-wrap {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 20px;
        width: 200px;
        margin: 0 auto;


        .btn {
            width: 100%;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);

            &:disabled {
                visibility: hidden;
            }
        }
    }

}

/*
 * 商品新規
 */

.items-table {
    max-width: 100%;

    .checkbox {
        width: 20px;
    }

    .code {
        width: 100px;
        white-space: nowrap;
    }

    .thumbnail {
        width: 80px;

        img {
            display: block;
            object-fit: cover;
            width: 64px;
            height: 64px;
        }
    }

    .name {
        width: auto;

        div {
            display: block;
        }

    }

    .type {
        width: 100px;
        white-space: nowrap;

        img {
            width: 100%;
        }
    }
}

.item_edit {

    .r_genre_id_wrap {
        display: block;
        border: solid 1px #666;
        padding: 5px;

        #r_genre_id_loading img {
            height: 1em;
        }


    }

    .form-check-label {
        white-space: nowrap;
    }

    .fa-question-circle {
        cursor: pointer;
        color: #ff6347;
        margin-left: .2em;
    }

    .subcodes_table__wrap {
        max-width: 100%;
        overflow-x: auto;
    }

    .subcodes_table {
        width: auto;
        max-width: 9999px;
        min-width: 3000px;
        border: none;
        white-space: nowrap;
        margin-top: 0;
        border-color: #ccc;

        tr {
            th {
                text-align: left;

            }

            td,
            th {
                padding: 0.5rem;
            }
        }

        .sku {
            width: 200px;
            position: sticky;
            left: 0;
            font-weight: 800;
            white-space: nowrap;
            text-overflow: ellipsis;
            z-index: 10;
        }

        .option {
            width: 100px;
            position: sticky;
            white-space: nowrap;
            text-overflow: ellipsis;
            z-index: 9;


        }

        .stock {
            width: 100px;
        }

        .jan {
            width: 200px;
        }

        [class^="attribute"] {

            /*        .attribute { */
            width: 200px;
            position: relative;

            .input-group,
            >input,
            >select {
                width: calc(100% - 30px);
            }

            .fa-regular {
                position: absolute;
                top: 18px;
                right: 8px;
                margin: auto;
                cursor: pointer;
                color: #0d6efd;
            }
        }

        thead {
            tr {
                th {
                    background-color: #eee;
                }
            }
        }

        tbody {
            tr {
                th {
                    background-color: #fafafa;
                }
            }
        }
    }


    .option__item {
        position: relative;

        .option_item-delete {
            position: absolute;
            top: 4px;
            right: 15px;
            color: #666;
        }

        &:first-child {
            .option_item-delete {
                display: none;
            }
        }
    }

    .select_stock_add_addcol,
    .select_stock_del_addcol {
        text-decoration: none;
        margin-right: .5em;
        font-size: 13px;
        white-space: nowrap;

        .fas {
            margin-right: .2em;
        }


    }

    form>h3:not(:first-child) {
        clear: both;
        margin-top: 3em;
        margin-bottom: 1em;
    }

    .selection__item {
        display: none;

        &.show {
            display: block;
        }

        .select_list_set {
            display: none;

            &.show {
                display: flex;
            }
        }
    }



    .selection__item__head {
        background: #ddd;
        padding: 10px;
        height: 60px;
    }

    .selection__item__body .row {
        margin-left: 20px;
    }

    .btn-category-modal {
        text-align: left;
    }

    #categoryModal {
        .category-tree {
            li {
                >span {
                    cursor: pointer;
                    color: #00f;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }


            }
        }

    }
}

.ne_csv {
    .table {
        th {
            white-space: nowrap;
        }
    }
}



.item_init {
    #jstree {
        margin: 0 15px;
        border: solid 1px #ccc;
        height: 500px;
        overflow-y: scroll;

        .jstree-checkbox {
            display: none;
        }

        .jstree-leaf {
            .jstree-checkbox {
                display: inline-block;
            }

            .jstree-icon.jstree-themeicon {
                display: none;
            }
        }
    }

    #variant_settings {
        display: none;
    }
}